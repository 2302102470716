import { anticipationMock } from './anticipation.mock';

export const featureFlags = {
  integrationAuthBaseUrl:
    'https://apihml-digital.cielo.com.br/cielo-digital-security-sys-hml/oauth-digital/v1/',
  recaptcha: {
    key: '6Ldp9hAkAAAAAFb8FuqRrvxk6FzJEbuU7OtJxCBj',
    enable: 'true'
  },
  integrationBaseUrl: {
    enable: false,
    url: 'https://apihml-digital.cielo.com.br/cielo-digital-prc-hml',
    enableJweJwtAssociation: false,
    jweJwtUrl:
      'https://apihml-digital.cielo.com.br/cielo-digital-security-sys-hml/oauth-digital-token/v1/jwe',
    disableSoftImpersonate: false,
    domain: 'https://apihml-digital.cielo.com.br'
  },
  dirf: {
    showDirf: {
      dec: 'true',
      excel: 'true',
      years: [2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016]
    },
    showInformYield: {
      pdf: 'true',
      years: [2021, 2020]
    },
    showDirfModal: 'false',
    showNaturalPersonInformYield: 'true',
    showDirfYieldOption: 'true'
  },
  maintenance: 'false',
  maintenanceMessage:
    'Olá, no momento estamos trabalhando para melhorar a sua experiência no nosso site :)',
  login: {
    accessBanks: {
      allowBanksLogin: 'true',
      link: ''
    }
  },
  onboarding: {
    enableOnboarding: 'true',
    enableP1Whatsapp: 'true',
    enableP1SMS: 'true',
    enableEditCPF: 'true',
    enableAcceptInvite: 'true',
    enableSettingsCall: 'true',
    enableBiometric: 'true',
    enableAccessManagement: 'true',
    enabledCreateInvite: 'true',
    enabledResendInvites: 'true',
    enabledUnlinkUser: 'true',
    enabledCustomerService: 'true',
    enabledRecheckOwner: 'true',
    enabledTechnicalProfile: 'true',
    enableForeignUser: 'true',
    enableSustainButtons: 'true',
    enableInternalForeignUser: 'true',
    enableAddEstablishmentUser: 'true',
    enableDetailedSearch: 'true',
    enableMassiveRegistration: 'true',
    enableSendingMassiveInvitations: 'true',
    enableCustomProfiles: 'true',
    enableRemoveButtonsAdminPanel: 'true'
  },
  services: {},
  whatsapp: {
    enableFeature: 'true',
    number: '551130035525',
    displayText: 'WhatsApp Cielo',
    displayNumber: '(11) 3003-5525',
    enableFloatingButton: 'true'
  },
  ecommerce: {
    superLink: 'true',
    transactions: 'true',
    checkoutCielo: 'true',
    cieloEcommerceApi: 'true',
    dashboard: 'true',
    dashboardEnabled: 'false',
    typedSale: 'true',
    consultEmittingBank: 'false',
    credential: 'true',
    mySuperlinkRouter: 'false',
    enableCheckoutMigration: 'true',
    enableWebservice3Migration: 'true',
    enableLinkDePagamentoMigration: 'true'
  },
  pix: {
    credentials: {
      massDeletion: 'false'
    },
    showNewOnboarding: 'true',
    showNewStatement: 'true',
    showNewPixBank: 'true'
  },
  bannerOffers: {
    ARRANCADA_BLINDAGEM_ALUGUEL_MDR_RR_MAIO: 'true',
    ARRANCADA_BLINDAGEM_ALUGUEL_MDR_MAIO: 'true',
    ARRANCADA_BLINDAGEM_ALUGUEL_MDR_RR_JUNHO: 'true',
    ARRANCADA_BLINDAGEM_ALUGUEL_MDR_JUNHO: 'true',
    ARRANCADA_BLINDAGEM_ALUGUEL_MDR_RR_JULHO: 'true',
    ARRANCADA_BLINDAGEM_ALUGUEL_MDR_JULHO: 'true',
    ARV_CURTO_PRAZO: 'true',
    ATENDIMENTO_WHATSAPP_SITE: 'true',
    ACOES_PREDITVAS_BATERIAS: 'true',
    BANNER_DIRF_SITE: 'true',
    PRODUTO_RECARGA_CELULAR_SITE: 'true',
    BCREDI: 'true',
    BF_FAROL: 'true',
    BF_SUPER_LINK: 'true',
    BANNER_RR_RECORRENTE_PROSPECTS: 'true',
    BANNER_SUPERLINK_DIA_DAS_MAES_SITE: 'true',
    BANNER_ID_DIGITAL: 'true',
    BANNER_INCENTIVO_PIX_SITE: 'true',
    BANNER_SUPERLINK_SITE: 'true',
    BANNER_P2M_SITE: 'true',
    BANNER_PIX_SITE: 'true',
    BANNER_FAROL_SITE: 'true',
    BANNER_FAROL_CONTRATADO_SITE: 'true',
    BANNER_SUPERLINK_PASCOA_SITE: 'true',
    BANNER_AUMENTO_VOLUME_BALCAO_CALL_LINK_SITE: 'true',
    BANNER_AUMENTO_VOLUME_BALCAO_PARLA_SITE: 'true',
    BANNER_CIELO_RESOLVE_NA_SITE: 'true',
    BANNER_ATUALIZACAO_CADASTRAL_SITE: 'true',
    BANNER_ARV_GRANDES_CONTAS_SITE: 'true',
    BANNER_ARV_MERCADO_SITE: 'true',
    BANNER_ARV_OFERTA_CRISE_NA_SITE: 'true',
    BANNER_ARV_NOVO_BANNER_NA_SITE: 'true',
    BANNER_ARV_SITE: 'true',
    BANNER_TEF_PIX_GRANDES_CONTAS: 'true',
    BANNER_ACESSE_E_GANHE_CANAIS_DIGITAIS_SITE: 'true',
    BANNER_ACAO_VOLUME_CREDITO: 'true',
    BANNER_ACAO_VOLUME_DEBITO: 'true',
    'BANNER_LINK-DE-PAGAMENTO_BAU_ATRIBUTOS_SITE': 'true',
    'BANNER_RR-SEGMENTADO_BAU_SITE': 'true',
    BANNER_FATURE_E_GANHE_SITE: 'true',
    'BANNER_NEGOCIOS-EM-MOVIMENTO_INSTITUCIONAL_SITE': 'true',
    'BANNER_FAROL-LINK-DE-PAGAMENTO_BLACK-FRIDAY_SITE': 'true',
    'BANNER_FATURE-E-GANHE_SITE': 'true',
    BANNER_RRSEGMENTADO_BLACKFRIDAY_NA_SITE: 'true',
    BANNER_RRDEGUSTACAO_BLACKFRIDAY_NA_SITE: 'true',
    BANNER_PIX_TAXAZERO_NA_SITE: 'true',
    CAPITAL_DE_GIRO_BB_SITE: 'true',
    CONECTA: 'true',
    CONVERSOR_DE_MOEDAS: 'true',
    CIELO_ACAO_RR_SELIC_E_ITC_MASTER: 'true',
    CIELO_ACAO_SELIC_RR: 'true',
    CRM_BONUS_ISENTOS: 'true',
    FACEBOOK_PAY: 'true',
    GIRO_EMPRESARIAL_BRADESCO: 'true',
    GIRO_FACIL_BRADESCO: 'true',
    MICROCREDITO_BRADESCO: 'true',
    OFERTA_ARRANCADA_CREDITO_RR: 'true',
    OFERTA_ARRANCADA_PARCELADO_RR: 'true',
    OFERTA_ARRANCADA_PARCELADO: 'true',
    OFERTA_BLACK_FRIDAY_RR_SITE: 'true',
    OFERTA_BLACK_FRIDAY_ARV_SITE: 'true',
    OFERTA_CREDIARIO: 'true',
    OFERTA_NOVOS_ECS_AVANCADO_20_A_50: 'true',
    OFERTA_NOVOS_ECS_AVANCADO_50_A_100: 'true',
    OFERTA_NOVOS_ECS_AVANCADO_100_A_250: 'true',
    OFERTA_NOVOS_ECS_AVANCADO_250_A_500: 'true',
    OFERTA_NOVOS_ECS_AVANCADO_ACIMA_500: 'true',
    OFERTA_NOVOS_ECS_INTER_20_A_50: 'true',
    OFERTA_NOVOS_ECS_INTER_50_A_100: 'true',
    OFERTA_NOVOS_ECS_INTER_100_A_250: 'true',
    OFERTA_NOVOS_ECS_INTER_250_A_500: 'true',
    OFERTA_NOVOS_ECS_INTER_ACIMA_500: 'true',
    OFERTA_SALDO_DEVEDOR: 'true',
    OFERTA_WINBACK_MDR_CREDITO: 'true',
    OFERTA_WINBACK_MDR_DEBITO: 'true',
    OFERTA_WINBACK_MDR_PARCELADO: 'true',
    PARCELADO_CLIENTE_AUTONOMO: 'true',
    PARCELADO_CLIENTE_SITE: 'true',
    PORTAL_DE_NEGOCIACAO_COBRANCA_SITE: 'true',
    PORTAL_DE_NEGOCIACAO_COBRANCA_SITE_EC_INATIVO: 'true',
    PORTAL_DE_NEGOCIACAO_PASCOA_SITE: 'true',
    PRODUTO_DCC_LIO_SITE: 'true',
    PIX: 'true',
    PIX_PARCEIRO: 'true',
    PORTAL_DE_NEGOCIACAO_COBRANCA_DESCONTO_ATIVO_SITE: 'true',
    PORTAL_DE_NEGOCIACAO_COBRANCA_DESCONTO_INATIVO_SITE: 'true',
    PRODUTO_RECARGA_CELULAR: 'true',
    PRODUTO_RR_SITE: 'true',
    BANNER_RR_BAU_BANCOBRASIL_SITE: 'true',
    PORTAL_DE_NEGOCIACAO_CARNAVAL_SITE: 'true',
    RECEBA_RAPIDO_MASSIVA: 'true',
    RECEBA_RAPIDO_MASSIVA_CARENCIA: 'true',
    RISE_ATIVACAO_CLIENTE_CONTROLE: 'true',
    RISE_ATIVACAO_CLIENTE_FISCAL: 'true',
    RR_SEMANA_CONSUMIDOR_SITE: 'true',
    SUPER_LINK: 'true',
    SIMULADOR_DE_VENDAS_CANAIS_DIGITAIS: 'true',
    TOKEN_CIELO: 'true',
    TAXA_ZERO_PIX: 'true',
    ATENDIMENTO_VAREJO_WHATSAPP_SITE: 'true',
    BANNER_APOIO_LITORAL_ARV_SITE: 'true',
    BANNER_APOIO_LITORAL_ALUGUEL_SITE: 'true',
    BANNER_APOIO_LITORAL_ALUGUEL_ARV_SITE: 'true',
    BANNER_ARV_DINAMICO_NA_SITE: 'true',
    BANNER_ARV_FOMENTO_BAU_SITE: 'true',
    OFERTA_PARA_LP_P2M_SITE: 'true',
    BANNER_DIA_DOS_PAIS_ARV_SITE: 'true',
    BANNER_DIA_DOS_PAIS_RR_SITE: 'true',
    BANNER_DIA_DOS_PAIS_SUPERLINK_SITE: 'true',
    BANNER_CIELO_TAP_SITE_TESTE_A: 'true',
    BANNER_CIELO_TAP_SITE_TESTE_B: 'true',
    BANNER_NOVO_HORARIO_ATENDIMENTO_ARV_SITE: 'true',
    BANNER_CIELO_TAP_VAREJO_SITE: 'true',
    BANNER_INDIQUE_E_GANHE_SITE: 'true',
    'BANNER_MVP_RECUPERACAO-DE-ATIVOS_NA_SITE': 'true',
    BANNER_NOVO_EXTRATO_SITE: 'true',
    BANNER_CONCILIADOR_CONTRATACAO_NO_SITE: 'true',
    BANNER_DCC_LANCAMENTO_DEBITO_NA_SITE: 'true',
    BANNER_ACOMPANHAMENTOPEDIDOMAQUINA_NA_SITE: 'true',
    BANNER_PIX_POSTECIPADO_NA_SITE: 'true',
    BANNER_PIX_BAU_TEF_SITE: 'true',
    BANNER_CIELOTAP_FOMENTO_NA_SITE: 'true',
    BANNER_PIX_DIA_DAS_MAES_NA_SITE: 'true',
    BANNER_LINK_DE_PAGAMENTO_LIBERADO_NA_SITE: 'true',
    BANNER_TAP_MENSALIDADE_ZERO_NA_SITE: 'true',
    BANNER_PIX_OFERTA_PAIXAO_AZUL_NA_SITE: 'true',
    BANNER_PIX_LIVRE_MOVIMENTACAO_NA_SITE: 'true',
    BANNER_PIX_NA_SITE_BAU: true,
    'BANNER_PIX-BASE-ATIVA_NA_SITE_BAU': true,
    'BANNER_PIX-NAO-CLIENTES_NA_SITE_BAU': true
  },
  enableCarousel: 'false',
  managementAccess: {
    technical: ['ecommerce', 'meuCadastro']
  },
  cieloWhatsapp: {
    wppBusinessUrl: 'https://wa.me/pay/virality/8c99bfda1a',
    withRR: {
      period: 2,
      rates: [
        {
          description: 'Débito',
          value: 0.99,
          period: '1 dia útil'
        },
        {
          description: 'Crédito à vista',
          value: 3.49,
          period: '2 dias úteis'
        }
      ]
    },
    noRR: {
      period: 30,
      rates: [
        {
          description: 'Débito',
          value: 0.99,
          period: '1 dia útil'
        },
        {
          description: 'Crédito à vista',
          value: 2.49,
          period: '30 dias'
        }
      ]
    },
    moreInfo: {
      period: '3 meses',
      rates: [
        {
          description: 'Débito',
          value: 1.99
        },
        {
          description: 'Crédito à vista',
          value: 3.99,
          period: '2 dias úteis'
        },
        {
          description: 'Crédito à vista',
          value: 4.99,
          period: '30 dias'
        }
      ]
    }
  },
  centralAtendimento: {
    principalNumber: '4002-5472',
    anotherRegionsNumber: '0800 570 8472'
  },
  tracking: {
    enable: 'true',
    orders: {
      enable: 'true'
    },
    requests: {
      enable: 'true'
    },
    supplies: {
      enable: 'true'
    }
  },
  anticipation: anticipationMock,
  mfeConfig: {
    recebiveis: {
      maintenanceMode: false,
      maintenanceModeMessage: 'Em manutenção',
      remoteUrl: 'https://extc-cnl-extrato-vendas-mfe.extc.hml.cieloaws'
    }
  },
  contactCielo: {
    enableFeature: true,
    number: '551130035525',
    especialistaRecebaRapido: {
      id: 3,
      label: 'Contrate com um especialista',
      title: 'Fale com um especialista pelo WhatsApp',
      text: 'Caso prefira contratar a antecipação com os nossos especialistas',
      qrcode: 'recebimento-automatico',
      whatsappNumber: '551130035525',
      linkWhatsapp:
        'https://api.whatsapp.com/send/?phone=551130035525&text=Recebimento%20Autom%C3%A1tico.%20Quero%20falar%20com%20um%20atendente%20para%20me%20explica%20melhor%20as%20taxas%20e%20me%20ajudar%20na%20contrata%C3%A7%C3%A3o.&type=phone_number&app_absent=0',
      router: ['recebimento-automatico', 'recebimento-automatico/ofertas'],
      enable: true
    },
    especialistaAntecipacao: {
      id: 2,
      label: 'Contrate com um especialista',
      title: 'Fale com um especialista pelo WhatsApp',
      text: 'Caso prefira contratar a antecipação com os nossos especialistas:',
      qrcode: 'antecipacao',
      whatsappNumber: '551130035525',
      linkWhatsapp:
        'https://api.whatsapp.com/send/?phone=551130035525&text=Quero+saber+mais+sobre+Antecipa%C3%A7%C3%A3o+de+Receb%C3%ADveis.+Quero+falar+com+um+atendente+para+me+explicar+melhor+as+taxas+e+me+ajudar+na+contrata%C3%A7%C3%A3o&type=phone_number&app_absent=0',
      router: ['antecipacao', 'antecipacao/avulsa', 'antecipacao/programada'],
      enable: true
    },
    duvidasGerais: {
      id: 1,
      label: 'Dúvidas gerais',
      title: 'Fale com a Cielo no WhatsApp',
      text: 'Tire suas dúvidas, faça elogios ou resolva um problema.',
      qrcode: 'duvidasGerais',
      whatsappNumber: '551130035525',
      linkWhatsapp:
        'https://api.whatsapp.com/send/?phone=551130035525&text&type=phone_number&app_absent=0',
      router: null,
      enable: true
    },
    gerenteVirtual: {
      id: 0,
      label: 'Gerente Virtual',
      title: 'WhatsApp do Gerente Virtual (das 9h às 17h)',
      text: 'Precisa falar sobre assuntos comerciais? Contate agora o Gerente Virtual.',
      qrcode: 'gerenteVirtual',
      whatsappNumber: '5511948032420',
      linkWhatsapp:
        'https://api.whatsapp.com/send/?phone=5511948032420&text&type=phone_number&app_absent=0',
      router: null,
      enable: true
    }
  },
  enableRedirectTransmitter: true,
  enableLikeInfinityAlert: true,
  enableRedirectPaymentsMFE: true,
  salesSimulation: {
    showCustomerInstallments: false,
    showColumnSaleAmount: false
  },
  taxasPlanos: {
    subMenu: {
      enable: true
    }
  },
  newItems: [
    {
      parent: 'MINHAS_VENDAS_E_EXTRATOS',
      child: 'SOLICITACAO_ESPECIAL'
    },
    {
      parent: 'PIX_MENU'
    },
    {
      parent: 'MANAGEMENT_OPEN_FINANCE'
    },
    {
      parent: 'PRODUTOS_E_SERVICOS',
      child: 'MINHAS_SOLICITACOES'
    }
  ],
  mfeDebugger: {
    showLinks: false
  },
  domicileManagement: {
    addDomicile: {
      enable: true
    },
    editDomicile: {
      enable: true
    }
  },
  salesCancellation: {
    redirectNovoCancelamento: true
  }
};
